<template>
    <div class="resetPassword xi">
        <nh-com></nh-com>
        <div class="banner">
            <div class="banner-wrap clearfix">
                <div class="banner-text">
                    <h3>个人中心</h3>
                    <p>当前位置：
                        <router-link to="/index">首页</router-link><i class="el-icon-arrow-right"></i><a>个人中心</a>
                    </p>
                </div>
            </div>
        </div>
        <div class="personal-content">
            <div class="personal-box clearfix">
                <div class="personal-left">
                    <hd-img></hd-img>
                </div>
                <div class="personal-right">
                    <div class="personal-right-tit">
                        <i class="iconfont">&#xe62c;</i><span> {{ id ? '编辑' : '新增' }} {{ collectionId ? '合集' : ''
                        }}<em>视频</em></span>
                    </div>
                    <div class="center-content">
                        <div class="iq-card-body">
                            <div class="from-box">
                                <el-form ref="videosForm" :model="videosForm" :rules="rules" label-width="150px">
                                    <el-form-item label="视频名称" prop="name">
                                        <el-input type="textarea" v-model="videosForm.name" maxlength="50" show-word-limit>
                                        </el-input>
                                    </el-form-item>
                                    <el-form-item label="视频来源" prop="source">
                                        <el-input type="text" v-model="videosForm.source">
                                        </el-input>
                                    </el-form-item>
                                    <el-form-item label="视频分类">
                                        <el-cascader v-model="videosForm.categoryId" :options="videosCategory"
                                            :props="{ checkStrictly: true, emitPath: false, value: 'id', label: 'name', children: 'children' }">
                                        </el-cascader>
                                    </el-form-item>
                                    <el-form-item label="视频类型">
                                        <el-radio-group v-model="videosForm.isCharge">
                                            <el-radio :label="false">免费</el-radio>
                                            <el-radio :label="true">收费</el-radio>
                                        </el-radio-group>
                                    </el-form-item>
                                    <el-form-item label="价格" v-if="videosForm.isCharge == true" prop="vipPrice">
                                        <el-col :span="11">
                                            <el-input class="w-18" v-model="videosForm.vipPrice"
                                                placeholder="价格"></el-input>
                                        </el-col>
                                    </el-form-item>
                                    <!-- <el-form-item label="非会员价" v-if="videosForm.isCharge == true" prop="userPrice">
                                        <el-col :span="11">
                                            <el-input class="w-18" v-model="videosForm.userPrice"
                                                placeholder="非会员价"></el-input>
                                        </el-col>
                                    </el-form-item> -->
                                    <el-form-item label="权限设置">
                                        <el-radio-group v-model="videosForm.limits">
                                            <el-radio :label="0">完全公开</el-radio>
                                            <!-- <el-radio :label="1">内部用户开放</el-radio> -->
                                            <el-radio :label="2">私密发布</el-radio>
                                        </el-radio-group>
                                    </el-form-item>
                                    <el-form-item label="视频介绍">
                                        <el-input type="textarea" v-model="videosForm.introduce"></el-input>
                                    </el-form-item>
                                    <el-form-item label="视频封面">
                                        <el-upload :headers="headers" :action="FileUploadUrl" class="avatar-uploader"
                                            :show-file-list="false" :on-success="handleAvatarSuccess"
                                            :before-upload="beforeAvatarUpload">
                                            <img v-if="videosForm.cover" :src="videosForm.cover" class="avatar">
                                            <i v-else class="el-icon-plus avatar-uploader-icon"></i>
                                            <div class="el-upload__tip" slot="tip">如果封面不上传，就默认视频的界面</div>
                                        </el-upload>
                                    </el-form-item>
                                    <el-form-item label="上传视频" style="margin-top:20px" prop="url">
                                        <el-upload class="avatar-uploader el-upload--text" action="" :show-file-list="false"
                                            accept=".mp4" :before-upload="beforeUploadVideo">
                                            <i v-if="videosForm.url == '' && percentage == 0"
                                                class="el-icon-plus avatar-uploader-icon"></i>
                                            <el-progress v-if="percentage < 100 && percentage > 0" type="circle"
                                                :percentage="percentage"></el-progress>
                                            <video v-if="videosForm.url && percentage == 100" :src="videosForm.url"
                                                class="avatar video-avatar" controls="controls"></video>

                                        </el-upload>
                                        <p v-if="isShowUploadVideo" class="text">请保证视频格式正确，且不超过20M</p>
                                    </el-form-item>
                                    <el-form-item class="btn-bottom">
                                        <el-button @click="cancelBtn('videosForm')">取消</el-button>
                                        <el-button type="primary" @click="saveBtn('videosForm')">保存</el-button>
                                    </el-form-item>
                                </el-form>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </div>
        <ft-com></ft-com>
    </div>
</template>

<script>

import '../../utils/aliyun-upload-sdk-1.5.3.min.js'
// import '../../utils/es6-promise.min.js'
import OSS from '../../utils/aliyun-oss-sdk-6.17.1.min.js'
window.OSS = OSS;
import ftCom from '../../components/footer'
import nhCom from "../../components/navheader";
import hdImg from "../../components/headerImg";
import { videoMain } from "../../components/video/video";
export default {
    name: "addVideo",
    computed: {
        headers() {
            return {
                "Authorization": 'Bearer' + ' ' + JSON.parse(localStorage.getItem('token')).value
            }
        }
    },
    components: {
        ftCom,
        nhCom,
        hdImg
    },
    data() {
        var video = new videoMain(this.TokenClient, this.Services.Statics)
        return {
            videoDomain: video,
            labelPosition: 'right',
            resetPassword: {
                oldPwd: '',
                newPwd: '',
                confirmPwd: ''
            },
            id: this.$route.query.videoId, //列表页id
            collectionId: this.$route.query.collectionId,
            videosForm: {
                name: '',
                categoryId: 1,
                isCharge: false,
                vipPrice: 0,
                userPrice: 0,
                limits: 0,
                introduce: '',
                cover: '',
                url: '',
                source: "",
                videoType: 1,// 1个人 2机构  3总后台
            },
            //视频
            uploadUrl: "",//你要上传视频到你后台的地址
            isShowUploadVideo: false, //显示上传按钮
            videoPath: "", //视频地址
            uploader: null,
            file: '',
            videosCategory: [],
            percentage: 0,
            rules: {
                name: [
                    { required: true, message: '请输入视频名称', trigger: 'blur' },
                ],
                vipPrice: [
                    { pattern: /(^[1-9]\d*(\.\d{1,2})?$)|(^0(\.\d{1,2})?$)/, message: "小数点后保留两位", trigger: "blur" },
                    { required: true, message: '请输入正确的金额', trigger: 'blur' },
                ],
                userPrice: [
                    { pattern: /(^[1-9]\d*(\.\d{1,2})?$)|(^0(\.\d{1,2})?$)/, message: "小数点后保留两位", trigger: "blur" },
                    { required: true, message: '请输入正确的金额', trigger: 'blur' },
                ],
                url: [
                    { required: true, message: '请上传视频地址', trigger: 'blur' },
                ],
            },
            FileUploadUrl: this.Services.Authorization + '/api/File/upload',
            // FileUploadUrl:'http://video.qyyl365.com'

        };
    },
    mounted() {
        this.LoadCategory()
        if (this.id) {
            this.LoadFrom()
        }
    },
    methods: {
        LoadFrom() {
            var _this = this;
            _this.videoDomain.VideoDetail(_this.id + '/' + 'true',
                function (data) {
                    _this.videosForm = data.data;
                    _this.percentage = 100

                }, function (err) { console.log(err); });
        },
        beforeUploadVideo(file) {
            console.log(file)
            //上传文件的大小
            const isLt20M = file.size / 1024 / 1024 < 20;
            //'video/ogg', 'video/flv', 'video/avi', 'video/wmv', 'video/rmvb','video/mp4' 可支持上传的视频类型
            // if (['video/mp4'].indexOf(file.type) == -1) {
            //     this.$message.error('请上传正确的视频格式');
            //     return false;
            // }
            // if (!isLt20M) {
            //     this.$message.error('上传视频大小不能超过20MB哦!');
            //     return false;
            // }
            this.isShowUploadVideo = false;
            this.uploader = this.createUploader()
            var userData = '{"Vod":{}}'
            this.uploader.addFile(file, null, null, null, userData)
            this.uploader.startUpload()
        },
        createUploader() {
            let _this = this;
            var uploader = new AliyunUpload.Vod({
                userId: "1922508017966810",
                //上传到点播的地域，默认值为'cn-shanghai'，//eu-central-1，ap-southeast-1，更多信息请参见存储说明
                region: "cn-shanghai",
                //开始上传
                addFileSuccess: function (uploadInfo) {
                    console.log(uploadInfo)
                },
                onUploadstarted: function (uploadInfo) {
                    console.log(uploadInfo)
                    let params = {
                        title: '视频名称',
                        fileName: uploadInfo.file.name
                    }
                    _this.videoDomain.VideoCertificate(params, function (data) {
                        uploader.setUploadAuthAndAddress(uploadInfo, data.data.uploadAuth, data.data.uploadAddress, data.data.videoId);
                        _this.videoId = data.data.videoId
                    }, function (error) {
                        console.log(error)
                    })
                },
                //文件上传成功
                onUploadSucceed: function (uploadInfo) {
                    _this.videosForm.url = "http://video.qyyl365.com/" + uploadInfo.object
                    _this.$refs['videosForm'].clearValidate(['url'])
                },
                //文件上传失败
                onUploadFailed: function (uploadInfo, code, message) {
                    uploader.stopUpload();
                },
                //文件上传进度，单位：字节
                onUploadProgress: function (uploadInfo, totalSize, loadedPercent) {
                    _this.percentage = parseInt((loadedPercent * 100).toFixed(2))
                },
                //上传凭证过期
                onUploadTokenExpired: function (uploadInfo) {

                },
                //全部文件上传结束
                onUploadEnd: function (uploadInfo) {

                }
            })
            return uploader;
        },
        LoadCategory() {
            var _this = this;
            _this.videoDomain.getCategories(
                function (data) {
                    _this.videosCategory = data.data;
                    _this.videosForm.categoryId = _this.videosCategory[0].id
                },
                function (err) {
                    console.log(err);
                });
        },
        beforeAvatarUpload(file) {
            const isJPG = (file.type === 'image/jpeg' || file.type === 'image/png');

            if (!isJPG) {
                this.$message.error('上传图片只能是 jpg/png 格式!');
            }
            return isJPG;
        },
        handleAvatarSuccess(res, file) {
            this.videosForm.cover = `${this.Services.Authorization}${res[0].completeFilePath}`;
        },
        cancelBtn(formName) {
            this.$refs[formName].resetFields();
            this.$router.go(-1);
        },
        editV() {
            var _this = this;
            _this.videosForm.userPrice = _this.videosForm.vipPrice
            _this.videoDomain.EditVideo(_this.videosForm,
                function (data) {
                    _this.$message({
                        type: 'success',
                        message: '保存成功'
                    });
                    _this.$router.go(-1);
                },
                function (err) {
                    console.log(err);
                });
        },
        addV() {
            var _this = this;
            _this.videoDomain.AddVideo(_this.videosForm,
                function (data) {
                    _this.$message({
                        type: 'success',
                        message: '保存成功'
                    });
                    _this.$router.go(-1);
                },
                function (err) {
                    console.log(err);
                });
        },
        saveBtn(formName) {
            var _this = this;
            _this.videosForm.userName = this.$store.state.localData.userInfo.name
            _this.videosForm.userId = this.$store.state.localData.userInfo.id
            console.log(_this.videosForm)
            _this.$refs[formName].validate((valid) => {
                if (valid) {
                    if (_this.collectionId) {
                        _this.videosForm.collectionId = _this.collectionId
                    }
                    if (_this.videosForm.id) {
                        if (_this.videoId) {
                            // 获取阿里云视频信息
                            _this.videoDomain.GetVideoInfo(_this.videoId,
                                function (data) {
                                    _this.videosForm.aliyunVideoId = data.data.videoId;
                                    _this.videosForm.aliyunCoverUrl = data.data.coverURL ? data.data.coverURL : '';
                                    _this.videosForm.size = data.data.size;
                                    _this.videosForm.duration = data.data.duration;
                                    _this.videosForm.storageLocation = data.data.storageLocation;
                                    _this.videoId = ""
                                    _this.editV()
                                },
                                function (err) {
                                    console.log(err);
                                });
                        } else {
                            _this.editV()
                        }

                    } else {
                        if (_this.videoId) {
                            // 获取阿里云视频信息
                            _this.videoDomain.GetVideoInfo(_this.videoId,
                                function (data) {
                                    _this.videosForm.aliyunVideoId = data.data.videoId;
                                    _this.videosForm.aliyunCoverUrl = data.data.coverURL ? data.data.coverURL : '';
                                    _this.videosForm.size = data.data.size;
                                    _this.videosForm.duration = data.data.duration;
                                    _this.videosForm.storageLocation = data.data.storageLocation;
                                    _this.videoId = ""
                                    _this.addV()
                                },
                                function (err) {
                                    console.log(err);
                                });
                        }
                    }
                } else {
                    return false;
                }
            });

        },
        onSubmit() {
            var _this = this;
        }
    }
}
</script>

<style scoped>
@import "../../assets/css/footer.css";

.banner {
    width: 100%;
    height: 160px;
    background: url("../../assets/img/news_banner.jpg") center no-repeat;
}

.iq-card-body {
    height: 100%;
    padding: 15px;
    border-radius: 15px;
    background: #FFFFFF;
}

.from-box {
    position: relative;
    margin-top: 20px;
    height: 100%;
    overflow-x: hidden;
    width: 100%;
    /*box-shadow:0px 10px 5px -10px #999;*/
}

.banner-wrap {
    position: relative;
    width: 1200px;
    height: 100%;
    margin: 0 auto;
}

.banner-text {
    float: left;
}

.banner-text h3 {
    padding-top: 35px;
    font-size: 26px;
    font-weight: normal;
    color: #ffffff;
}

.banner-text p {
    font-size: 14px;
    color: #ffffff;
    margin-top: 20px;
}

.banner-text p a {
    color: #ffffff;
    font-size: 14px;
    margin: 0 5px;
}

/**/
.personal-content {
    background: #f7f7f7;
    padding: 40px 0;
}

.personal-box {
    width: 1200px;
    margin: 0 auto;
}

.personal-left {
    float: left;
}

.personal-right {
    float: right;
    width: 900px;
    background: #FFFFFF;
    box-sizing: border-box;
}

.personal-right-tit {
    border-bottom: 1px solid #e5e5e5;
    padding: 20px;
}

.personal-right-tit .iconfont {
    color: #04a290;
    font-size: 24px;
    margin-right: 5px;
}

.personal-right-tit span {
    display: inline-block;
    font-size: 24px;
    font-weight: 700;
    color: #333333;
}

.personal-right-tit span em {
    font-style: normal;
    color: #04a290;
}

.personal-right>form {
    padding: 30px 15px;
    width: 460px;
}
</style>
<style>
.personal-center {
    display: none;
}
</style>
